<template>
  <div class="element-icon-wrap">
    <div class="icon-list">
      <ul>
        <li
          v-for="(item, index) in iconList"
          :key="index"
          :class="[iconName === item ? 'activeColor' : '']"
          @click="selectIcon(item)"
        >
          <i :class="item"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      iconName: '',
      iconList: ['el-icon-s-help',
        'el-icon-s-cooperation',
        'el-icon-s-order',
        'el-icon-s-platform',
        'el-icon-s-fold',
        'el-icon-s-unfold',
        'el-icon-s-operation',
        'el-icon-s-promotion',
        'el-icon-s-home',
        'el-icon-s-release',
        'el-icon-s-ticket',
        'el-icon-s-management',
        'el-icon-s-open',
        'el-icon-s-shop',
        'el-icon-s-marketing',
        'el-icon-s-flag',
        'el-icon-s-comment',
        'el-icon-s-finance',
        'el-icon-s-claim',
        'el-icon-s-custom',
        'el-icon-s-opportunity',
        'el-icon-s-data',
        'el-icon-s-check',
        'el-icon-s-grid',
        'el-icon-menu']
    }
  },
  methods: {
    selectIcon (name) {
      console.log(name)
      this.iconName = name
      this.$emit('iconEmit', name)
    }
  }
}
</script>

<style lang="stylus" scoped>
.element-icon-wrap
  .icon-list
    ul li
      float left
      width 56px
      height 56px
      text-align center
      line-height 56px
      cursor pointer
      i
        font-size 22px
        color #fff
      &:hover
        i
          color #ffa134
      &.activeColor
        i
          color #ffa134 !important
</style>
