<template>
  <div class="basic-form-wrap">
    <div class="basic-header">
      <div class="logo">
        <img :src="logoUrl" />
      </div>
      <div class="step">
        <el-steps
          :active="active"
          align-center
          finish-status="success"
        >
          <el-step title="基本信息"></el-step>
          <el-step title="表单设计"></el-step>
          <el-step title="流程设计"></el-step>
        </el-steps>
      </div>
      <div
        class="btns"
        v-if="active === 0"
      >
        <span
          class="prev-btn"
          @click="reset"
        >重置</span>
        <span
          :class="[inputLen === 5 ? 'next-btn' : 'isDisabled']"
          @click="keepBasicBtn()"
        >下一步</span>
      </div>
      <div
        class="btns"
        v-if="active === 1"
      >
        <span
          class="prev-btn"
          @click="prevBtn(0)"
        >上一步</span>
        <span
          class="preview"
          @click="preview()"
        >预 览</span>
        <span
          class="next-btn"
          @click.stop="keepDynamicForm"
        >保存并下一步</span>
      </div>
      <div
        class="btns"
        v-if="active === 2"
      >
        <span
          class="prev-btn"
          @click="prevBtn(1)"
        >上一步</span>
        <span
          class="preview"
          @click="previewBpmn()"
        >预 览</span>
        <span
          class="next-btn"
          @click="submitProcess"
        >保存并完成</span>
      </div>
    </div>
    <div class="content">
      <keep-alive>
        <component
          ref="basicRef"
          :is="componentId"
          @keepSuccessEmit="keepSuccessEmit"
          @isNextEmit="isNextEmit"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Bus from '@/utils/bus.js'
import basicMsg from './components/basicMsg.vue'
import formConfig from '@/views/workOrderCenter/dynamicForm/formConfiguration.vue'
import bpmn from '@/components/bpmnBuilder/index.vue'
export default {
  data () {
    return {
      active: 0,
      logoUrl: 'https://img3.winshang.com/wbms/logo.png',
      componentId: 'basicMsg',
      inputLen: 0
    }
  },
  components: {
    basicMsg,
    formConfig,
    bpmn
  },
  mounted () {
    this.active = this.$route.query.active - 0
    if (this.active === 0) {
      this.componentId = 'basicMsg'
    } else if (this.active === 1) {
      this.componentId = 'formConfig'
    } else {
      this.componentId = 'bpmn'
    }
    Bus.$on('saveFormSuccessEmit', () => {
      this.$router.push({
        query:
        {
          id: this.$route.query.id,
          active: 2,
          projectId: this.$route.query.projectId,
          edit: this.$route.query.edit || 0
        }
      })
      this.componentId = 'bpmn'
      this.active = 2
    })
    // console.log(this.componentId)
  },
  methods: {
    submitProcess () {
      this.$refs.basicRef.submitProcess()
    },
    reset () {
      this.$refs.basicRef.basicReset()
    },
    previewBpmn () {
      Bus.$emit('previewBpmn')
    },
    preview () {
      Bus.$emit('previewFormEmit')
    },
    keepDynamicForm () {
      Bus.$emit('keepDynamicFormEmit')
    },
    keepBasicBtn () {
      this.$refs.basicRef.keepBasic()
    },
    prevBtn (index) {
      this.$router.push({
        path: '/basicForm',
        query: {
          id: this.$route.query.id,
          active: index,
          projectId: this.$route.query.projectId,
          edit: this.$route.query.edit || 0
        }
      })
      if (index === 0) {
        this.active = 0
        this.componentId = 'basicMsg'
      } else if (index === 1) {
        this.active = 1
        this.componentId = 'formConfig'
        this.$refs.basicRef.shouldGetNewRule = true
      }
    },
    keepSuccessEmit (active) {
      this.active = active
      this.componentId = 'formConfig'
    },
    isNextEmit (val) {
      this.inputLen = val
    }
  }
}
</script>

<style lang="stylus" scoped>
.basic-form-wrap
  height 88px
  background-color #272930
  .basic-header
    display flex
    .logo
      width 33%
      display flex
      height 88px
      padding-left 24px
      align-items center
    .step
      width 33%
      text-align center
    .btns
      text-align right
      display flex
      height 88px
      align-items center
      margin-left auto
      margin-right 24px
      .prev-btn
        height 38px
        line-height 38px
        border 1px solid #FFA134
        width 138px
        text-align center
        color #FFA134
        font-size 16px
        border-radius 2px
        cursor pointer
      .preview, .next-btn
        height 40px
        line-height 40px
        width 140px
        text-align center
        background-color #FFA134
        color #fff
        font-size 16px
        border-radius 2px
        margin-left 16px
        cursor pointer
      .isDisabled
        height 40px
        line-height 40px
        width 140px
        text-align center
        font-size 16px
        border-radius 2px
        margin-left 16px
        cursor pointer
        background-color #81591b
        color rgba(255, 255, 255, 0.5)
</style>
