<template>
  <div class="basic-info-step1">
    <div class="basic-info">
      <div class="title">基本信息</div>
      <div class="info-wrap">
        <div class="info-item">
          <div class="info-text"><span>*</span>流程名称</div>
          <div class="basic-input">
            <el-input
              type="text"
              maxlength="8"
              v-model="name"
              placeholder="请输入流程名称"
            ></el-input>
          </div>
        </div>
        <div class="info-item">
          <div class="info-text">流程分组</div>
          <div class="basic-input">
            <el-select
              v-model="groupId"
              popper-class="w-block-select-down"
              placeholder="请选择流程分组"
              @visible-change="groupVisibleChange"
            >
              <el-option
                v-for="item in groupList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="info-item">
          <div class="info-text"><span>*</span>流程描述</div>
          <div class="basic-input">
            <el-input
              type="text"
              maxlength="16"
              v-model="describe"
              placeholder="请输入流程应用简述"
            ></el-input>
          </div>
        </div>
        <!-- <div class="info-item">
          <div class="info-text"><span>*</span>数据库表名 <el-tooltip
              class="item"
              effect="dark"
              content="工单创建成功后，系统将在数据库中创建数据表，请输入4-18位纯英文字母。"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <div class="basic-input">
            <el-input
              type="text"
              v-model="groupId"
              maxlength="18"
              minlength="4"
              placeholder="请输入数据库表名"
              @input="groupId = groupId.replace(/[^\a-zA-z]/g, '')"
            ></el-input>
          </div>
        </div> -->
        <div class="basic-icon">
          <div class="info-text"><span>*</span>流程图标</div>
          <el-popover
            placement="right"
            width="400"
            trigger="click"
            popper-class="icon-block-popover"
          >
            <div
              class="select-icon"
              slot="reference"
              :style="{'backgroundColor' : color1 }"
            >
              <i :class="iconName"></i>
            </div>
            <elementIcon @iconEmit="iconEmit"></elementIcon>
          </el-popover>
        </div>
        <div class="basic-color">
          <div class="info-text"><span>*</span>图标颜色</div>
          <div class="select-color">
            <el-color-picker
              v-model="color2"
              @change="activeChange"
            ></el-color-picker>
          </div>
        </div>
        <div class="checked-item">
          <div class="info-text">允许商户发起 <el-tooltip
              class="item"
              effect="dark"
              content="允许商户发起时，商户在小程序端可以发起此流程。"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <div class="select-check">
            <el-checkbox v-model="isOutside">允许</el-checkbox>
          </div>
        </div>
        <div class="checked-item">
          <div class="info-text">流程服务评价 <el-tooltip
              class="item"
              effect="dark"
              content="开通流程服务评价时，系统将在流程完结后，由发起者填写服务评价反馈。"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <div class="select-check">
            <el-checkbox v-model="isEvaluation">开通</el-checkbox>
          </div>
        </div>
        <div class="checked-item">
          <div class="info-text"><span>*</span>流程关闭人 <el-tooltip
              class="item"
              effect="dark"
              content="允许由谁关闭流程。"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <div class="select-check">
            <el-checkbox v-model="isInitiatorClose">发起人</el-checkbox>
            <el-checkbox v-model="isHandlerClose">处理人</el-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import elementIcon from './elementIcon.vue'
import api from '@/api'
export default {
  data () {
    return {
      name: '',
      describe: '',
      groupId: undefined,
      color1: '#373a43',
      color2: '#F89407',
      iconName: 'el-icon-plus',
      isOutside: false,
      isEvaluation: true,
      isInitiatorClose: true,
      isHandlerClose: true,
      groupList: [],
      version: 0,
      id: 0
    }
  },
  components: {
    elementIcon
  },
  computed: {
    isHasValueText: function () {
      let isHasValue = 0
      isHasValue = Number(Boolean(this.name)) + Number(Boolean(this.describe)) + Number(Boolean(this.iconName !== 'el-icon-plus')) + Number(Boolean(this.color2)) + Number(Boolean(this.isHandlerClose) || Boolean(this.isInitiatorClose))
      return isHasValue
    }
  },
  watch: {
    isHasValueText (newVal, oldVal) {
      if (newVal === 5) {
        this.$emit('isNextEmit', newVal)
      } else {
        this.$emit('isNextEmit', newVal)
      }
    }
  },
  mounted () {
    this.getGroupList()
    if (this.$route.query.id) {
      this.getBasicFunc()
    }
  },
  methods: {
    groupVisibleChange (val) {
      if (val) {
        this.getGroupList()
      }
    },
    getBasicFunc () {
      this.axios.get(api.getBasic + '/' + this.$route.query.id).then(res => {
        this.name = res.data.data.name
        this.describe = res.data.data.desc
        this.groupId = res.data.data.groupId || ''
        this.color2 = res.data.data.iconColour
        this.iconName = res.data.data.icon || 'el-icon-plus'
        this.isOutside = res.data.data.isOutside !== 0
        this.isEvaluation = res.data.data.isEvaluation !== 0
        this.isInitiatorClose = res.data.data.isInitiatorClose !== 0
        this.isHandlerClose = res.data.data.isHandlerClose !== 0
        this.version = res.data.data.version
        this.id = res.data.data.id
      })
    },
    getGroupList () {
      const data = {
        projectId: this.$route.query.projectId
      }
      this.axios.post(api.getFlowConfigListByGroup, data)
        .then((res) => {
          this.groupList = res.data.data.filter(item => {
            return item.groupId > 0
          }).map(item => {
            const obj = {
              value: item.groupId,
              label: item.groupName
            }
            return obj
          })
        })
    },
    basicReset () {
      this.name = ''
      this.describe = ''
      this.groupId = undefined
      this.color1 = '#373a43'
      this.color2 = '#F89407'
      this.iconName = 'el-icon-plus'
      this.isOutside = false
      this.isEvaluation = true
      this.isInitiatorClose = true
      this.isHandlerClose = true
    },
    keepBasic () {
      if (this.isHasValueText !== 5) {
        return
      }
      if (!this.name) {
        this.$message({
          type: 'warning',
          message: '请输入流程名称！'
        })
        return
      }
      if (this.name.length > 8) {
        this.$message({
          type: 'warning',
          message: '流程名称不能超过8个字！'
        })
        return
      }
      // if (!this.groupId) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请选择流程分组！'
      //   })
      //   return false
      // }
      if (!this.describe) {
        this.$message({
          type: 'warning',
          message: '请输入流程描述！'
        })
        return false
      }
      if (this.iconName === 'el-icon-plus') {
        this.$message({
          type: 'warning',
          message: '请选择流程图标！'
        })
        return false
      }
      if (!this.color2) {
        this.$message({
          type: 'warning',
          message: '请选择图标颜色！'
        })
        return false
      }
      if (!this.isInitiatorClose && !this.isHandlerClose) {
        this.$message({
          type: 'warning',
          message: '请至少选择一个流程关闭人！'
        })
        return false
      }
      const data = {
        projectId: this.$route.query.projectId,
        groupId: this.groupId,
        icon: this.iconName,
        iconColour: this.color2,
        name: this.name,
        desc: this.describe,
        isEvaluation: this.isEvaluation ? 1 : 0,
        isHandlerClose: this.isHandlerClose ? 1 : 0,
        isInitiatorClose: this.isInitiatorClose ? 1 : 0,
        isOutside: this.isOutside ? 1 : 0,
        version: this.version,
        id: 0
      }
      let apiStr
      if (this.$route.query.id) {
        data.id = this.$route.query.id
        apiStr = api.updateBasic
      } else {
        apiStr = api.saveBasic
      }
      this.axios.post(apiStr, data)
        .then((res) => {
          // console.log(res.data.data)
          if (res.data.code === 0) {
            this.$router.push({
              path: '/basicForm',
              query: {
                id: res.data.data || this.$route.query.id,
                active: 1,
                projectId: this.$route.query.projectId,
                edit: this.$route.query.edit || 0
              }
            })
            this.$emit('keepSuccessEmit', 1)
          }
        })
    },
    activeChange (e) {
      // console.log(e)
      // this.color1 = e
    },
    iconEmit (name) {
      this.iconName = name
    }
  }
}
</script>

<style lang="stylus" scoped>
.basic-info
  width 600px
  height calc(100vh - 88px)
  margin auto
  // background-color rgba(0, 0, 0, 0.1)
  padding-top 64px
  .title
    font-size 16px
    font-weight bold
    color #FFFFFF
    text-align center
  .info-wrap
    margin-top 52px
    .info-item
      display flex
      align-items center
      margin-bottom 24px
      .info-text
        width 120px
        margin-right 24px
        text-align right
        color #fff
        font-size 14px
        span
          color #EE2D41
    .basic-icon
      display flex
      margin-bottom 24px
      .info-text
        width 120px
        margin-right 24px
        text-align right
        color #fff
        font-size 14px
        span
          color #EE2D41
      .select-icon
        width 56px
        height 56px
        background-color #373A43
        border-radius 2px
        text-align center
        line-height 56px
        cursor pointer
        line-height 60px
        i
          color #fff
          font-size 24px
        .el-icon-plus
          color #808191
    .basic-color
      display flex
      align-items center
      margin-bottom 35px
      .info-text
        width 120px
        margin-right 24px
        text-align right
        color #fff
        font-size 14px
        span
          color #EE2D41
    .checked-item
      display flex
      margin-bottom 38px
      .info-text
        width 120px
        margin-right 24px
        text-align right
        color #fff
        font-size 14px
        span
          color #EE2D41
</style>
